.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-paper {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	padding: 20px;
	background-color: #dee2e0;
}

.language-select {
  float: right;
  color: #fff;
}

